@import '../../styles/customMediaQueries.css';

.root,
.rootCard {
  z-index: 1;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
}

.root {
  composes: buttonSmall from global;

  top: 12px;
  right: 24px;

  @media (--viewportMedium) {
    top: 19px;
    right: 24px;
  }
}

.rootCard {
  top: 12px;
  right: 12px;

  border: none;
  border-radius: 12px 12px 16px 16px;
  width: 32px;
  min-height: unset;
  height: 32px;
  padding: 0;

  background-color: rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.icon {
  width: 16px;
  height: 16px;
}

.iconCard {
  width: 24px;
  height: 24px;

  fill: var(--colorWhite);
}

.text {
  /* margin-bottom: 2px; */
  margin-left: 8px;
}
