@import '../../styles/customMediaQueries.css';

.root {
}

.button {
  composes: textXSmall from global;

  border: none;
  padding: 0;

  text-decoration: underline;
  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColorDark);

    cursor: pointer;
  }
}

.modalContent {
  margin-top: 42px;

  overflow-x: hidden;

  @media (--viewportMedium) {
    margin-top: 15px;
  }
}

.imgDesktop,
.imgMobile {
  border-radius: var(--borderRadius);
}

.imgDesktop {
  display: none;

  @media (--viewportMedium) {
    display: block;

    max-width: 1024px;
  }
}

.imgMobile {
  margin: 0 -48px;
  max-width: calc(100% + 96px);

  @media (--viewportMedium) {
    display: none;
  }
}
