@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
}

.withBorder,
.withBackground {
  border-radius: var(--borderRadiusMedium);
  padding: 8px 12px;

  @media (--viewportMedium) {
    padding: 12px 16px;
  }
}

.withBorder {
  border: 1px solid;
}

.borderInformation {
  border-color: var(--colorInformation);
}

.borderAttention {
  border-color: var(--colorAttention);
}

.borderHelp {
  border-color: var(--colorInformation);
}

.borderSuccess {
  border-color: var(--colorSuccess);
}

.backgroundInformation {
  background-color: var(--colorInformationLight);

  & > svg {
    color: var(--colorInformation);
  }
}

.backgroundAttention {
  background-color: var(--colorAttentionLight);

  & > svg {
    color: var(--colorAttention);
  }
}

.backgroundHelp {
  background-color: var(--colorInformationLight);

  & > svg {
    color: var(--colorInformation);
  }
}

.backgroundSuccess {
  background-color: var(--colorSuccessLight);

  & > svg {
    color: var(--colorSuccess);
  }
}

.icon {
  flex-shrink: 0;

  margin: 0 0 0 8px;
}

.textWrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.img {
  margin-bottom: 16px;
  border-radius: var(--borderRadius);

  max-width: 100%;
  height: auto;
}

.title {
  composes: p from global;

  margin: 0 0 8px;
  padding: 0;

  font-weight: var(--fontWeightSemiBold);
}

.text {
  composes: marketplaceSmallFontStyles from global;

  white-space: pre-line;
}
